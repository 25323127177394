import api from 'app/utils/api';

export class ComplianceService {
  public static getTransactions(
    searchValue: string = '',
    filterStatuses: string[] = [],
    page: number = 1,
    limit: number = 10
  ) {
    return api.get(
      `/transaction?search=${searchValue}&status=${filterStatuses}&page=${page}&limit=${limit}`
    );
  }

  public static getTransactionByID(transactionId: string) {
    return api.get(`/transaction/${transactionId}`);
  }

  public static getTransactionDocumentByID(
    transactionId: string,
    documentId: string
  ) {
    return api.get(`/transaction/${transactionId}/documents/${documentId}`);
  }

  public static createTransaction(transactionName: string) {
    const data = {
      transactionName: transactionName,
    };
    return api.post('/transaction', data);
  }

  public static archiveTransaction(transactionID: string) {
    return api.patch(`/transaction/${transactionID}/archive`);
  }

  public static updateStatus(transactionID: string, status: string) {
    const data = {
      status,
    };
    return api.patch(`/transaction/${transactionID}/status`, data);
  }

  // TODO: Once we no longer require file type, implement multi document upload
  public static uploadDocuments(transactionID: string, files: any[]) {
    const formData = new FormData();
    files.forEach((file) => {
      formData.append('file', file);
    });
    console.log('Form Data:', formData);
    return api.put(`/transaction/${transactionID}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  }

  public static getTransactionDocument(
    transactionID: string,
    documentID: string
  ) {
    return api.get(`/transaction/${transactionID}/documents/${documentID}`, {
      responseType: 'arraybuffer',
    });
  }

  public static getTransactionWithDocumentDetails(
    transactionID: string,
    documentID: string
  ) {
    return api.get(
      `transaction/${transactionID}/document_details/${documentID}`
    );
  }

  public static validateDocuments(transactionID: string) {
    return api.patch(`/transaction/${transactionID}/validate_documents`);
  }

  public static verificationUpdate(
    transactionID: string,
    documentID: string,
    errorActions: Record<string, string>[]
  ) {
    const data = {
      errorActions,
    };

    return api.patch(
      `/transaction/${transactionID}/documents/${documentID}`,
      data
    );
  }

  public static getAgents() {
    return api.get('/compliance/agents');
  }

  public static assignRealtor(
    transactionID: string,
    agentID: string,
    role: string
  ) {
    const data = {
      agentID,
      role,
    };
    return api.patch(`/transaction/${transactionID}/agent`, data);
  }

  public static checkDocuments(transactionID: string) {
    return api.get(`/transaction/${transactionID}/transaction-completeness`);
  }

  public static getAgent(query: string) {
    return api.get(`/agent?search=${query}&limit=5`);
  }

  public static postCheck(
    agentID: string,
    documentType: string,
    file: File,
    newTransaction: boolean,
    receivingOfficeId: string,
    transactionName?: string,
    transactionID?: string,
    address?: any
  ) {
    const formData = new FormData();
    formData.append('transactionName', transactionName ?? '');
    formData.append('agentID', agentID);
    formData.append('file', file);
    formData.append('documentType', documentType);
    formData.append('newTransaction', newTransaction ? 'true' : 'false');
    formData.append('transactionID', transactionID ?? '');
    formData.append('receivingOfficeId', receivingOfficeId);
    formData.append('address', JSON.stringify(address));

    return api.post('/transaction/checks', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  }

  public static getCheckByID(checkID: string) {
    return api.get(`/transaction/checks/${checkID}`);
  }

  public static getDocumentByID(transactionID: string, documentID: string) {
    return api.get(`/transaction/${transactionID}/documents/${documentID}`, {
      responseType: 'arraybuffer',
    });
  }

  public static patchCheck(
    checkID: string,
    readyToDeposit: boolean,
    remarks: string,
    status: string
  ) {
    const data = {
      readyToDeposit,
      remarks,
      processingStatus: {
        status,
      },
    };
    return api.patch(`/transaction/checks/${checkID}`, data);
  }

  public static patchReadyToDeposit(checkID: string, readyToDeposit: boolean) {
    const data = {
      readyToDeposit,
    };
    return api.patch(`/transaction/checks/${checkID}/ready-to-deposit`, data);
  }

  public static deleteCheck(checkID: string) {
    return api.patch(`/transaction/checks/${checkID}/archive`);
  }
  public static getPropertyAddress(query: string) {
    return api.get(`/transaction?search=${query}`);
  }

  public static async updateCheckErrors(
    checkId: string,
    updatedIssues: {
      checkErrors: { required: string[]; optional: string[] };
      checkErrorsFormatted: { required: string[]; optional: string[] };
    }
  ) {
    try {
      const response = await api.patch(
        `/transaction/checks/${checkId}/update-validation-result`,
        updatedIssues
      );
      return response.data;
    } catch (error) {
      console.error('Error updating check errors:', error);
      throw error;
    }
  }

  // // Tried to set up client side rendering of the document but it is not working
  // public static getDocumentv2(documentID: string) {
  //   return api.get(`/compliance/document/${documentID}`);
  // }
}
