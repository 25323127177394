import { Auth, getAuth } from 'firebase/auth';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import { ThemeProvider, createTheme } from '@mui/material';
import { getApps, initializeApp } from 'firebase/app';
import { lazy, useEffect } from 'react';
import {
  getDataConsentScreenShown,
  setDataConsentScreenShown,
  setProfiles,
  setUser,
} from 'app/utils/localStorageHandler/userProfile';

import { Auth0WithNavigate } from './app/components/auth/Auth0Provider';
import { ConfigProvider } from 'antd';
import OfficeAdminDashboard from 'app/pages/OfficeAdmin';
import ProtectedRoute from './app/components/auth/ProtectedRoute';
import { RecoilRoot } from 'recoil';
import RoleProtectedRoute from 'app/components/auth/AgentProtectedRoute';
import { ToastContainer } from 'react-toastify';
import { UserService } from 'app/services/user';
import environment from 'app/config/environment';
import useSuspense from 'app/hooks/UseSuspense';
import CookieBanner from 'app/pages/Shared/CookieBanner';

import { initializePendo } from 'app/utils/pendo';

import { initializeClarity } from 'app/utils/clarity';

// Lazy loading components
const AgentDashboard = lazy(() => import('app/pages/Agent/Dashboard'));
const AgentLoginPage = lazy(
  () => import('./app/pages/Agent/Login/AgentLoginPage')
);
const AgentOnboardingAddEmailPage = lazy(
  () => import('./app/pages/Agent/Onboarding/AddEmailPage')
);
const AgentOnboardingCompletedPage = lazy(
  () => import('./app/pages/Agent/Onboarding/CompletedPage')
);
const AgentOnboardingCreateAccountPage = lazy(
  () => import('./app/pages/Agent/Onboarding/CreateAccountPage')
);
const ComplianceDashboard = lazy(
  () => import('app/pages/Compliance/Dashboard')
);
const ComplianceLoginPage = lazy(() => import('app/pages/Compliance/Login'));
const CustomerContractPage = lazy(
  () => import('app/pages/Customer/Contract/CustomerContractPage')
);
const EmailLabelsAuthCompleted = lazy(
  () => import('app/pages/Agent/Onboarding/EmailLabelsAuthCompleted')
);
const EmailLabelsAuthPage = lazy(
  () => import('app/pages/Agent/Onboarding/EmailLabelsAuthPage')
);
const LandingPage = lazy(() => import('app/pages/LandingPage'));
const NotFound404Page = lazy(() => import('./app/pages/Errors/NotFound'));
const PrivacyPolicyPage = lazy(() => import('app/pages/Shared/PrivacyPolicy'));

let firebaseAuth: Auth;
// TODO: Edit the rest if required
const theme = createTheme({
  palette: {
    primary: {
      // light: '#757ce8',
      main: '#2F78EB',
      // dark: '#002884',
      // contrastText: '#fff',
    },
    // secondary: {
    //   light: '#ff7961',
    //   main: '#f44336',
    //   dark: '#ba000d',
    //   contrastText: '#000',
    // },
  },
});

function App() {
  if (process.env.NODE_ENV === 'production') {
    console.log = () => {};
  }

  // Initialize Firebase Auth
  const firebaseConfig = {
    apiKey: environment.firebase.FIREBASE_API_KEY,
    authDomain: environment.firebase.FIREBASE_AUTH_DOMAIN,
    projectId: environment.firebase.FIREBASE_PROJECT_ID,
    storageBucket: environment.firebase.FIREBASE_STORAGE_BUCKET,
    messagingSenderId: environment.firebase.FIREBASE_MESSAGING_SENDER_ID,
    appId: environment.firebase.FIREBASE_APP_ID,
    measurementId: environment.firebase.FIREBASE_MEASUREMENT_ID,
  };

  let app;
  if (!getApps().length) {
    app = initializeApp(firebaseConfig);
  } else {
    app = getApps()[0];
  }

  firebaseAuth = getAuth(app);
  const userID = localStorage.getItem('userId') || '';
  const partnerID = JSON.parse(localStorage.getItem('partnerId') || '{}');
  // TODO: Once auth is setup, use ProtectedRoutes for the routes that require authentication
  //   Also if user is logged in, the /agent/login should redirect to /agent/dashboard
  // TODO: zIndexes are haphazardly set without much thought, will have to set it properly.

  // const prefix = '/v2';
  const prefix = '';

  // List of routes
  const routes = [
    {
      path: `${prefix}/agent/login`,
      element: useSuspense(AgentLoginPage),
    },
    {
      path: `${prefix}/compliance/login`,
      element: useSuspense(ComplianceLoginPage),
    },
    {
      path: `${prefix}/agent/dashboard/*`,
      element: <RoleProtectedRoute Component={AgentDashboard} Role="agent" />,
    },
    {
      path: `${prefix}/compliance/dashboard/*`,
      element: (
        <RoleProtectedRoute Component={ComplianceDashboard} Role="compliance" />
      ),
    },
    {
      path: `${prefix}/office-admin/dashboard/*`,
      element: (
        <RoleProtectedRoute
          Component={OfficeAdminDashboard}
          Role="officeAdmin"
        />
      ),
    },
    {
      path: `${prefix}/agent/onboarding/create-account`,
      element: useSuspense(AgentOnboardingCreateAccountPage),
    },
    {
      path: `${prefix}/agent/onboarding/add-email`,
      element: useSuspense(AgentOnboardingAddEmailPage),
    },
    {
      path: `${prefix}/agent/email-labels`,
      element: useSuspense(EmailLabelsAuthPage),
    },
    {
      path: `${prefix}/agent/onboarding/completed`,
      element: useSuspense(AgentOnboardingCompletedPage),
    },
    {
      path: `${prefix}/agent/email-labels/completed`,
      element: useSuspense(EmailLabelsAuthCompleted),
    },
    {
      path: `${prefix}/contract/:token`,
      element: <ProtectedRoute Component={CustomerContractPage} />,
    },
    {
      path: `${prefix}`,
      element: useSuspense(LandingPage),
    },
    {
      path: `/privacy-policy`,
      element: useSuspense(PrivacyPolicyPage),
    },
    {
      path: `${prefix}/*`,
      element: useSuspense(NotFound404Page),
    },
    {
      path: '',
      element: useSuspense(NotFound404Page),
    },
  ];

  useEffect(() => {
    const userSvc = new UserService();
    userSvc.getUserProfile().then(
      (response) => {
        setProfiles(response?.data?.profiles);
        setUser(response?.data?.userId);
        setDataConsentScreenShown(response?.data?.dataTrackingScreenShown || true);
        if (response?.data?.dataTrackingConsent === 'accepted') {
          if (userID && partnerID) initializePendo(userID, partnerID);
          if (userID)
            initializeClarity(userID, 'MAXHOME-WEB', 'Max Landing Page');
        }
      },
      (error) => {
        console.error('Error fetching user profile: ', error);
      }
    );
  }, []);

  return (
    <>
      <RecoilRoot>
        <ThemeProvider theme={theme}>
          <ConfigProvider theme={{ token: { colorPrimary: '#2F78EB' } }}>
            <ToastContainer />
            {/* Uncomment this div to enable firebase ui auth */}
            {/* <div id="firebaseui-auth-container"></div> */}
            <Router>
              <Auth0WithNavigate>
                <Routes>
                  {routes.map((route, index) => (
                    <Route
                      key={index}
                      path={route?.path}
                      element={route.element}
                    />
                  ))}
                </Routes>
              </Auth0WithNavigate>
            </Router>
            {!getDataConsentScreenShown() ? (
              <CookieBanner privacyPolicyUrl="/privacy-policy" />
            ) : null}
          </ConfigProvider>
        </ThemeProvider>
      </RecoilRoot>
    </>
  );
}

export { firebaseAuth };
export default App;
