import React, { useEffect, useState } from 'react';
import { TextField, Grid } from '@mui/material';
import AutocompleteInput from './AutocompleteInput';
import { AddressService } from './AddressService/AddressService';

interface Address {
  unitNumber: string;
  streetAddress: string;
  city: string;
  state: string;
  zip: string;
  country: string;
  placeId: string;
}

interface AddressFormProps {
  query: string;
  setFormData: React.Dispatch<
    React.SetStateAction<{
      propertyAddress: { value: string; label: string };
      selectedAgentName: { value: string; label: string };
      selectedDocumentType: { value: string; label: string };
      address: any;
      selectedOffice: { value: string; label: string };
    }>
  >;
}

const AddressForm: React.FC<AddressFormProps> = ({ query, setFormData }) => {
  const [address, setAddress] = useState<Address>({
    unitNumber: '',
    streetAddress: query || '',
    city: '',
    state: '',
    zip: '',
    country: '',
    placeId: '',
  });

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAddress({
      ...address,
      [e.target.name]: e.target.value,
    });
  };

  const handleAddressSelect = async (selectedAddress: any) => {
    const response: any = await AddressService.getPlaceDetails(
      selectedAddress.placeId
    );
    setAddress((prevState) => ({
      ...prevState,
      ...response.data,
      streetAddress: selectedAddress.description,
      placeId: selectedAddress.placeId,
    }));
  };

  const handleAddressInputChange = (value: string) => {
    setAddress({
      unitNumber: '',
      streetAddress: value,
      city: '',
      state: '',
      zip: '',
      country: '',
      placeId: '',
    });
  };

  const textFields = [
    { name: 'unitNumber', label: 'Unit number', required: false, xs: 12 },
    { name: 'city', label: 'City', required: true, xs: 6 },
    { name: 'zip', label: 'ZIP', required: true, xs: 6 },
    { name: 'state', label: 'State', required: true, xs: 12 },
  ];

  useEffect(() => {
    setFormData((prev: any) => ({ ...prev, address: address }));
  }, [address]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <AutocompleteInput
          value={address.streetAddress}
          onAddressSelect={handleAddressSelect}
          onInputChange={handleAddressInputChange}
        />
      </Grid>
      {textFields.map((field) => (
        <Grid item xs={field.xs} key={field.name}>
          <TextField
            fullWidth
            label={field.label}
            name={field.name}
            value={address[field.name as keyof Address]}
            onChange={handleInputChange}
            required={field.required}
          />
        </Grid>
      ))}
    </Grid>
  );
};

export default AddressForm;
