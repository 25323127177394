import './FilterComponent.scss';

import { DatePicker, Drawer, Radio, Select, SelectProps, Tag } from 'antd';
import React, { useEffect, useState } from 'react';

import FilterButton from './FilterButton';
import { OfficeService } from 'app/services/office';
import { Typography } from '@mui/material';
import dayjs from 'dayjs';
import { getOfficeAdminOffice } from 'app/utils/localStorageHandler/userProfile';
import useDebounce from 'app/hooks/UseDebounce';

interface SelectOption {
  value: string;
  label: string;
}

interface InitialFilters {
  statuses: string[];
  checkTypes: string[];
  transactionOffices: Record<string, string>[];
  readyToScan: boolean | null;
  readyToScanLabel: string;
  statusLabels: string[];
}

const statuses: SelectOption[] = [
  { value: 'NOT_STARTED,PROCESSING', label: 'Under Review' },
  {
    value: 'LOOP_NOT_FOUND,DOCS_MISSING,VALIDATION_FAILED,PRISM_NOT_FOUND',
    label: 'Needs Action',
  },
  { value: 'COMPLETED', label: 'Max Approved' },
  { value: 'DEPOSITED', label: 'Deposited' },
];

const checkTypes: SelectOption[] = [
  {
    value: 'IL_BW_CC',
    label: 'Commission Check',
  },
  {
    value: 'IL_BW_EMR',
    label: 'Earnest Money Receipt',
  },
  {
    value: 'IL_BW_EMP',
    label: 'Earnestly',
  },
];

type TagRender = SelectProps['tagRender'];

const FilterComponent: React.FC<{
  isCompliance: boolean | undefined;
  onFilterChange: (
    statuses: string[],
    checkTypes: string[],
    transactionOffices: string[],
    receivingOffices: string[],
    readyToScan: boolean | null,
    labels: string[],
    dateFilter: { gte: string; lte: string },
    depositDateFilter: { gte: string; lte: string }
  ) => void;
  initialFilters: InitialFilters;
}> = ({ isCompliance, onFilterChange, initialFilters }) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [selectedStatuses, setSelectedStatuses] = useState<string[]>(
    initialFilters.statuses
  );
  const [statusLabels, setStatusLabels] = useState<string[]>(
    initialFilters.statusLabels
  );
  const [selectedCheckTypes, setSelectedCheckTypes] = useState<string[]>(
    initialFilters.checkTypes
  );
  const [checkTypeLabels, setCheckTypeLabels] = useState<string[]>([]);

  const [readyToScan, setReadyToScan] = useState<boolean | null>(
    initialFilters.readyToScan
  );
  const [readyToScanLabel, setReadyToScanLabel] = useState<string>(
    initialFilters.readyToScanLabel
  );
  const [activeFiltersCount, setActiveFiltersCount] = useState<number>(0);

  const [filteredTransactionOffices, setFilteredTransactionOffices] = useState<
    Record<string, any>[]
  >([]);
  const [transactionOfficeQuery, setTransactionOfficeQuery] =
    useState<string>('');
  const [selectedTransactionOffices, setSelectedTransactionOffices] = useState<
    Record<string, string>[]
  >([]);
  const [dateFilter, setDateFilter] = useState<{ gte: string; lte: string }>({
    gte: '',
    lte: '',
  });
  const [depositDateFilter, setDepositDateFilter] = useState<{
    gte: string;
    lte: string;
  }>({
    gte: '',
    lte: '',
  });

  const [filteredReceivingOffices, setFilteredReceivingOffices] = useState<
    Record<string, any>[]
  >([]);
  const [receivingOfficeQuery, setReceivingOfficeQuery] = useState<string>('');
  const [selectedReceivingOffices, setSelectedReceivingOffices] = useState<
    Record<string, string>[]
  >([]);

  const [transactionOfficeSelectDisabled, setTransactionOfficeSelectDisabled] =
    useState<boolean>(false);
  const [receivingOfficeSelectDisabled, setReceivingOfficeSelectDisabled] =
    useState<boolean>(false);

  const debouncedTransactionOfficeQuery = useDebounce(
    transactionOfficeQuery,
    100
  );

  const debouncedReceivingOfficeQuery = useDebounce(receivingOfficeQuery, 100);

  const toggleDropdown = () => setShowDropdown(!showDropdown);

  const clearFilters = () => {
    setSelectedStatuses([]);
    setSelectedCheckTypes([]);
    setSelectedTransactionOffices([]);
    setSelectedReceivingOffices([]);
    setStatusLabels([]);
    setCheckTypeLabels([]);
    setReadyToScan(null);
    setReadyToScanLabel('');
    setActiveFiltersCount(0);
    setDateFilter({ gte: '', lte: '' });
    setDepositDateFilter({ gte: '', lte: '' });
  };

  const handleStatusSelect = (value: string[]) => {
    console.log('Status values: ', value);

    // Maintain a list of the labels as well for UI rendering
    if (value.length === 0 || value.includes('')) {
      setStatusLabels([]);
    } else {
      let newStatusLabels: string[] = [];
      value.map((val) => {
        let label = statuses.find((o) => o.value === val)?.label;
        if (label) {
          newStatusLabels.push(label);
        }
      });
      setStatusLabels(newStatusLabels);
    }

    setSelectedStatuses(value);
  };

  // ---------- Check type select handler methods ----------
  const handleCheckTypeSelect = (value: string[]) => {
    console.log('Check type values: ', value);

    // Maintain a list of the labels as well for UI rendering
    if (value.length === 0 || value.includes('')) {
      setCheckTypeLabels([]);
    } else {
      let newCheckTypeLabels: string[] = [];
      value.map((val) => {
        let label = checkTypes.find((o) => o.value === val)?.label;
        if (label) {
          newCheckTypeLabels.push(label);
        }
      });
      setCheckTypeLabels(newCheckTypeLabels);
    }

    setSelectedCheckTypes(value);
  };

  // ---------------------------------------------------------------
  // ---------- Office Selector common methods ---------------------
  const resetSelectOfficeFilters = () => {
    setSelectedTransactionOffices([]);
    setSelectedReceivingOffices([]);
    setTransactionOfficeSelectDisabled(false);
    setReceivingOfficeSelectDisabled(false);
  };

  // ---------- Transaction office select handler methods ----------

  const handleTransactionOfficeOnSelect = (option: Record<string, any>) => {
    console.log('Selected Transaction Office: ', option);
    setSelectedTransactionOffices([...selectedTransactionOffices, option]);

    if (!isCompliance) {
      const office = getOfficeAdminOffice();
      console.log('Office: ', office);

      // Set the receiving office to the selected transaction office if it is different
      if (office && office._id !== option.value) {
        setSelectedReceivingOffices([
          {
            value: office._id,
            label: office.name,
          },
        ]);
        // Disable the receiving office select
        setReceivingOfficeSelectDisabled(true);
      }
    }
  };

  const handleTransactionOfficeOnDeselect = (option: Record<string, any>) => {
    console.log('Deselected Transaction Office: ', option);
    setSelectedTransactionOffices(
      selectedTransactionOffices.filter(
        (office) => office.value !== option.value
      )
    );
    if (!isCompliance && selectedTransactionOffices.length === 1) {
      setReceivingOfficeSelectDisabled(false);
      setSelectedReceivingOffices([]);
    }
  };

  // ---------- Receiving office select handler methods ----------
  const handleReceivingOfficeOnSelect = (option: Record<string, any>) => {
    console.log('Selected Receiving Office: ', option);
    setSelectedReceivingOffices([...selectedReceivingOffices, option]);
    if (!isCompliance) {
      const office = getOfficeAdminOffice();
      console.log('Office: ', office);

      // Set the transaction office to the selected receiving office if it is different
      if (office && office._id !== option.value) {
        setSelectedTransactionOffices([
          {
            value: office._id,
            label: office.name,
          },
        ]);
        // Disable the transaction office select
        setTransactionOfficeSelectDisabled(true);
      }
    }
  };

  const handleReceivingOfficeOnDeselect = (option: Record<string, any>) => {
    console.log('Deselected Receiving Office: ', option);
    setSelectedReceivingOffices(
      selectedReceivingOffices.filter((office) => office.value !== option.value)
    );
    if (!isCompliance && selectedReceivingOffices.length === 1) {
      setTransactionOfficeSelectDisabled(false);
      setSelectedTransactionOffices([]);
    }
  };

  // ---------------------------------------------------------------
  // ---------- Ready to scan radio button handler methods ---------

  // Handle ready to scan select
  // TODO: Consider moving this to a map when implementing close feature from the chips itself
  const handleReadyToScanRadioButton = (value: boolean | null) => {
    console.log('value', value);
    let readyToScanLabel = '';

    switch (value) {
      case true:
        readyToScanLabel = 'Ready to Submit';
        break;
      case false:
        readyToScanLabel = 'Not Ready to Submit';
        break;
      default:
        readyToScanLabel = '';
        break;
    }

    setReadyToScanLabel(readyToScanLabel);
    setReadyToScan(value);
  };

  // --------------------------------------------
  // ---------- UseEffect hooks -----------------

  /**
   * Fetch the transaction offices based on the debounced query
   */
  useEffect(() => {
    // Depending on the mode, the partner ID will be different
    // While this might not be a real use case yet, it is good to have this in place
    const mode = isCompliance ? 'Compliance' : 'OfficeAdmin';

    OfficeService.getOffices(mode, debouncedTransactionOfficeQuery, 1, 10).then(
      (response) => {
        setFilteredTransactionOffices(response.data?.data || []);
      }
    );
  }, [debouncedTransactionOfficeQuery]);

  /**
   * Fetch the receiving offices based on the debounced query
   */
  useEffect(() => {
    // Depending on the mode, the partner ID will be different
    // While this might not be a real use case yet, it is good to have this in place
    const mode = isCompliance ? 'Compliance' : 'OfficeAdmin';

    OfficeService.getOffices(mode, debouncedReceivingOfficeQuery).then(
      (response) => {
        setFilteredReceivingOffices(response.data?.data || []);
      }
    );
  }, [debouncedReceivingOfficeQuery]);

  /**
   * Trigger the onFilterChange callback when the selected statuses, transaction offices or ready to scan value changes
   */
  useEffect(() => {
    const updatedLabels = computeUpdatedLabels();
    setActiveFiltersCount(updatedLabels.length);
    let selectedTransactionOfficesIDs = selectedTransactionOffices.map(
      (office) => office.value
    );
    let selectedReceivingOfficesIDs = selectedReceivingOffices.map(
      (office) => office.value
    );
    onFilterChange(
      selectedStatuses,
      selectedCheckTypes,
      selectedTransactionOfficesIDs,
      selectedReceivingOfficesIDs,
      readyToScan,
      updatedLabels,
      dateFilter,
      depositDateFilter
    );
  }, [
    selectedStatuses,
    selectedTransactionOffices,
    selectedReceivingOffices,
    readyToScan,
    selectedCheckTypes,
    dateFilter,
    depositDateFilter,
  ]);

  // --------------------------------------------------------
  // ---------- Select Tag render methods -------------------

  /**
   * Custom Tag render for the check type select's selected options/tags
   * @param props The props for the tag render
   * @returns The tag component for the status select
   */
  const selectedCheckTypeTagRender: TagRender = (props) => {
    const { value, closable, onClose } = props;
    const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
      event.preventDefault();
      event.stopPropagation();
    };

    const label = checkTypes.filter((o) => o.value === value)[0].label;

    return (
      <Tag
        onMouseDown={onPreventMouseDown}
        closable={closable}
        onClose={onClose}
        style={{
          margin: '0.25rem',
          padding: '0.25rem',
          fontSize: '0.9rem',
          borderRadius: '16px',
        }}
      >
        {label}
      </Tag>
    );
  };

  /**
   * Custom Tag render for the status select's selected options/tags
   * @param props The props for the tag render
   * @returns The tag component for the status select
   */
  const selectStatusTagRender: TagRender = (props) => {
    const { value, closable, onClose } = props;
    const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
      event.preventDefault();
      event.stopPropagation();
    };

    const label = statuses.filter((o) => o.value === value)[0].label;
    // const { backgroundColor, color, border } = StatusChipStyleMap[label];
    return (
      <Tag
        onMouseDown={onPreventMouseDown}
        closable={closable}
        onClose={onClose}
        style={{
          margin: '0.25rem',
          padding: '0.25rem',
          fontSize: '0.9rem',
          // backgroundColor,
          // color,
          // border,
          borderRadius: '16px',
        }}
      >
        {label}
      </Tag>
    );
  };

  /**
   * Custom Tag render for the transaction office select's selected options/tags
   * @param props The props for the tag render
   * @returns The tag component for the transaction office select
   */
  const selectTransactionOfficeTagRender: TagRender = (props) => {
    const { label, closable, onClose } = props;
    const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
      event.preventDefault();
      event.stopPropagation();
    };

    return (
      <Tag
        onMouseDown={onPreventMouseDown}
        closable={closable}
        onClose={onClose}
        style={{
          margin: '0.25rem',
          padding: '0.3rem',
          fontSize: '0.9rem',
          // backgroundColor: '#2F78EB',
          // color: 'white',
          borderRadius: '16px',
        }}
      >
        {label}
      </Tag>
    );
  };

  /**
   * Custom Tag render for the receiving office select's selected options/tags
   * @param props The props for the tag render
   * @returns The tag component for the receiving office select
   */
  const selectReceivingOfficeTagRender: TagRender = (props) => {
    const { label, closable, onClose } = props;
    const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
      event.preventDefault();
      event.stopPropagation();
    };

    return (
      <Tag
        onMouseDown={onPreventMouseDown}
        closable={closable}
        onClose={onClose}
        style={{
          margin: '0.25rem',
          padding: '0.3rem',
          fontSize: '0.9rem',
          // backgroundColor: '#2F78EB',
          // color: 'white',
          borderRadius: '16px',
        }}
      >
        {label}
      </Tag>
    );
  };

  // ------------------------------------------------
  // ----------- Utility methods --------------------

  /**
   *  Compute the updated labels based on the selected statuses, transaction offices and ready to scan value
   *  @returns Updated labels
   */
  const computeUpdatedLabels = () => {
    let updatedLabels: string[] = [];

    if (selectedStatuses.length > 0) {
      updatedLabels = [...statusLabels];
    }
    if (selectedCheckTypes.length > 0) {
      updatedLabels = [...updatedLabels, ...checkTypeLabels];
    }
    if (selectedTransactionOffices.length > 0) {
      let transactionOfficeLabels = selectedTransactionOffices.map(
        (office) => office.label
      );
      updatedLabels = [...updatedLabels, ...transactionOfficeLabels];
    }
    if (selectedReceivingOffices.length > 0) {
      let receivingOfficeLabels = selectedReceivingOffices.map(
        (office) => office.label
      );
      updatedLabels = [...updatedLabels, ...receivingOfficeLabels];
    }

    if (readyToScan !== null) {
      updatedLabels = [...updatedLabels, readyToScanLabel];
    }
    if (dateFilter.gte && dateFilter.lte) {
      updatedLabels.push(`Date: ${dateFilter.gte} to ${dateFilter.lte}`);
    }
    if (depositDateFilter.gte && depositDateFilter.lte) {
      updatedLabels.push(
        `Deposit Date: ${depositDateFilter.gte} to ${depositDateFilter.lte}`
      );
    }

    return updatedLabels;
  };

  // -------------------------------------------------
  // ---------- Filter Component JSX -----------------

  return (
    <div className="filter-component">
      <FilterButton
        onClick={toggleDropdown}
        clearFilters={clearFilters}
        selectedCount={activeFiltersCount}
      />
      <Drawer
        title={<Typography fontSize="1.5rem">Filters</Typography>}
        open={showDropdown}
        onClose={toggleDropdown}
        width={350}
      >
        {/* Status filter section */}
        <div className="filter-section">
          <Typography fontSize="1rem" marginBottom="0.5rem">
            Status
          </Typography>
          <Select
            mode="multiple"
            className="select-component"
            placeholder="Select Status"
            tagRender={selectStatusTagRender}
            value={selectedStatuses}
            onChange={(value) => handleStatusSelect(value)}
            options={statuses}
            optionRender={(option) => <div>{option.label}</div>}
            allowClear
          />
        </div>

        {/* Check type filter section */}
        <div className="filter-section">
          <Typography fontSize="1rem" marginBottom="0.5rem">
            Check Type
          </Typography>
          <Select
            mode="multiple"
            className="select-component"
            placeholder="Select Check Type"
            tagRender={selectedCheckTypeTagRender}
            value={selectedCheckTypes}
            onChange={(value) => handleCheckTypeSelect(value)}
            options={checkTypes}
            optionRender={(option) => <div>{option.label}</div>}
            allowClear
          />
        </div>

        {/* Ready to submit filter section */}
        <div className="filter-section">
          <Typography fontSize="1rem" marginBottom="0.5rem">
            Ready to Submit
          </Typography>
          <Radio.Group
            className="ready-to-scan-radio"
            onChange={(event) =>
              handleReadyToScanRadioButton(event.target.value)
            }
            defaultValue={null}
            value={readyToScan}
          >
            <Radio.Button value={true}>Yes</Radio.Button>
            <Radio.Button value={false}>No</Radio.Button>
            <Radio.Button value={null}>All</Radio.Button>
          </Radio.Group>
        </div>

        {/* Transaction office filter section */}
        <div className="filter-section">
          <Typography fontSize="1rem" marginBottom="0.5rem">
            Branch Office
          </Typography>
          <Select
            mode="multiple"
            className="select-component"
            placeholder="Select Branch Office"
            tagRender={selectTransactionOfficeTagRender}
            value={selectedTransactionOffices}
            onSearch={(value) => {
              console.log('Value: ', value);
              setTransactionOfficeQuery(value);
            }}
            filterOption={false}
            onSelect={(value, option) => {
              handleTransactionOfficeOnSelect(option);
            }}
            onDeselect={(value, option) =>
              handleTransactionOfficeOnDeselect(option)
            }
            onClear={resetSelectOfficeFilters}
            onBlur={() => setTransactionOfficeQuery('')}
            options={filteredTransactionOffices.map((office) => ({
              value: office._id,
              label: office.name,
            }))}
            allowClear
            disabled={transactionOfficeSelectDisabled}
          />
        </div>

        {/* Receiving office filter section */}
        <div className="filter-section">
          <Typography fontSize="1rem" marginBottom="0.5rem">
            Receiving Office
          </Typography>
          <Select
            mode="multiple"
            className="select-component"
            placeholder="Select Receiving Office"
            tagRender={selectReceivingOfficeTagRender}
            value={selectedReceivingOffices}
            onSearch={(value) => {
              console.log('Value: ', value);
              setReceivingOfficeQuery(value);
            }}
            filterOption={false}
            onSelect={(value, option) => {
              handleReceivingOfficeOnSelect(option);
            }}
            onDeselect={(value, option) =>
              handleReceivingOfficeOnDeselect(option)
            }
            onClear={resetSelectOfficeFilters}
            onBlur={() => setTransactionOfficeQuery('')}
            options={filteredReceivingOffices.map((office) => ({
              value: office._id,
              label: office.name,
            }))}
            allowClear
            disabled={receivingOfficeSelectDisabled}
          />
        </div>

        {/* Date filter section */}
        <div className="filter-section">
          <Typography fontSize="1rem" marginBottom="0.5rem">
            Submission Date
          </Typography>
          <DatePicker.RangePicker
            disabledDate={(current) => current > dayjs()}
            onChange={(value) => {
              if(!value) {
                setDateFilter({ gte: '', lte: '' });
              }
              if (value) {
                setDateFilter({
                  gte: value[0]?.format('MM-DD-YYYY') || '',
                  lte: value[1]?.format('MM-DD-YYYY') || '',
                });
              }
            }}
          />
        </div>

        {/* Deposit date filter section */}
        <div className="filter-section">
          <Typography fontSize="1rem" marginBottom="0.5rem">
            Deposit Date
          </Typography>
          <DatePicker.RangePicker
            disabledDate={(current) => current > dayjs()}
            onChange={(value) => {
              if(!value) {
                setDepositDateFilter({ gte: '', lte: '' });
              }
              if (value) {
                setDepositDateFilter({
                  gte: value[0]?.format('MM-DD-YYYY') || '',
                  lte: value[1]?.format('MM-DD-YYYY') || '',
                });
              }
            }}
          />
        </div>
      </Drawer>
    </div>
  );
};

export default FilterComponent;
